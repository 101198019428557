<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
    >   
      <CForm id="travel_form" method="POST" enctype="multipart/form-data">
          <CRow>
            <CCol md="12">
              <div role="group" class="form-group">
                <label>Fecha:</label>
                <v-date-picker
                  id="date"
                  name="date"
                  class="date-picker"
                  v-model="form.date"
                  locale="es-MX" size="sm"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <label>Monto Pagado (MXN):</label>
              <CInput class="input_centered"
                id="amount"
                name="amount"
                placeholder="Monto"
                v-model="form.amount"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <label>Observaciones:</label>
              <CTextarea v-model="form.observations" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                id="observations"
                name="observations"
                rows="3"
                text="Must be 8-20 words long."
              ></CTextarea>
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="validate" :color="color">Validar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'PaymentModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Pago",
      color: "info",
      payment: false,
      form: this.getEmptyForm(),
      submitted: false,
      content: "<h1>Some initial content</h1>",
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  mounted: async function() {

  },
  methods: {
    validateModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Validar Pago';
      this.submitted = false;

      let date = payment.date != null ? moment(payment.date, "YYYY-MM-DD") : moment();

      this.form = {
        id: payment.id,
        amount: payment.amount,
        date: date.toDate(),
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        observations: payment.observations
      };

      this.payment = payment;
    },
    validate () {
      this.$emit("validation", this.form );

      this.closeModal();
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      let date = moment();

      let form = {
          travel_id: 0,
          enrollment_id: 0,
          student_id: 0,
          customer_id: 0,
          payment_id: 0,
          amount: 0,
          date: date.toDate(),
          voucher: '',
          observations: ''
      };

      return form;
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      date: {
        required
      },
      amount: {
        required
      }
    }
  },
}
</script>