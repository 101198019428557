<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
        :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        :itemsPerPageSelect="{label: 'Pagos por página: ', values: [10,20,30,50,100]}"
      >
        <template #date="{item}"><td class="w100 text-center">{{item.date | date}}</td></template>
        <template #travel="{item}"><td class="w400 text-center">{{item.school.name}} <br /> <strong>{{item.travel.name}}</strong></td></template>
        <template #student="{item}"><td class="w400 text-center"><strong>{{item.student.name}}</strong> <br /> {{item.student.customer_name}}</td></template>
        <template #amount="{item}"><td class="w150 text-center">{{item.amount | currency}}</td></template>
        <template #name="{item}">
          <td class="w100 text-center">
            <span v-if="item.amount <= 100 || item.amount == 88888 || item.amount == 99999">N/D<br /></span>
            <span v-if="item.amount > 100 && item.amount != 88888 && item.amount != 99999">
              <span>
                <span>
                  <CButton v-if="item.status != 'validated'" color="warning" size="sm" @click="updatePaymentModal(item.travel, item.student, item)" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">PAGADO</CButton>
                  <CButton v-if="item.status == 'validated'" color="success" size="sm" @click="updatePaymentModal(item.travel, item.student, item)" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">CONFIRMADO</CButton>
                  <CButton color="info" style="height: 27px; padding:0px 6px 0 7px; margin-left: 5px;" size="sm" @click="downloadReceipt(item.student, item)" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}"><CIcon name="cil-file"/></CButton>
                </span>
              </span>
            </span>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <PaymentModal ref="paymentModal" @validate="validateProcess"></PaymentModal>
  </CCard>
</template>

<script>
import PaymentModal from './PaymentModal.vue'
import ws from '../../services/payments';
import store from '../../store'

export default {
  name: 'PaymentsTable',
  components: { PaymentModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'date', label: 'Fecha'},
          {key: 'travel', label: 'Escuela / Viaje'},
          {key: 'student', label: 'Alumno / Padre'},
          {key: 'amount', label: 'Monto'},
          {key: 'name', label: 'Status'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    downloadReceipt (student, payment) {
      var link = document.createElement('a');
      link.href = payment.filename;
      link.download = payment.filename.substr(payment.filename.lastIndexOf('/') + 1);
      link.click();
    },
    updatePaymentModal (travel, student, payment) {
      this.$refs.paymentModal.validateModal(student, payment, travel.exchange_rate);
    },
    async validateProcess(data) {
      this.loading();

      let response = await ws.validate(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
